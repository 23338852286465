/// /////////////////////接口请求，具体对应和参数参考文档////////////////////
import http from '@/utils/http'
class Api {
  /*
  * 通用请求
  */
  post = (code: string, data?: any) => {
    return http.post('/admin/ser/api/' + code, data)
  }
  /*
  * 登录 - 获取令牌
  */
  getToken = (data: any) => {
    return http.post('/admin/login/get-token-m', data)
  }
  /**
   * 获取验证码
   */
  getVerCode = (data: any) => {
    return http.post('admin/login/send-verifycode', data)
  }
  /*
  * 统一登录
  */
  TYLOGIN = (data: any) => {
    return http.post('/admin/login/get-token-t', data)
  }
}
export default new Api()